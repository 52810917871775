import SlimSelect from 'slim-select';

class FormField {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-formfield',
            filledClassname: 'form__input-is--filled',
            focusedClassname: 'form__input-is--focused',
            errorClassname: 'form__input-has--error'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$formField = $element;

        this.initialize();
    }

    initialize () {
        this.initField(this.$formField);
    }

    initField ($element) {
        this.checkField($element);
        const formType = $element.getAttribute(this.settings.initAttr); // eslint-disable-line
        switch (formType) {
        case 'select': {
            const $field = $element.querySelector('select');
            let closeOnSelect = false;
            let allowDeselect = true;
            if ($field.getAttribute('multiple') === null) {
                closeOnSelect = true;
                allowDeselect = false;
            }
            const fieldAPI = new SlimSelect({
                select: $field,
                settings: {
                    allowDeselect: allowDeselect,
                    closeOnSelect: closeOnSelect,
                    showSearch: false,
                    placeholderText: $field.getAttribute('placeholder')
                },
                events: {
                    beforeOpen: () => {
                        $element.classList.add(this.settings.focusedClassname);
                    },
                    beforeClose: () => {
                        $element.classList.remove(this.settings.focusedClassname);
                    }
                }
            });
            $element.fieldAPI = fieldAPI;
            break;
        }
        }

        $element.querySelector('select, input, textarea').addEventListener('change', () => {
            $element.classList.remove(this.settings.errorClassname);
            this.checkField($element);
        });

        $element.querySelector('select, input, textarea').addEventListener('focus', () => {
            $element.classList.remove(this.settings.errorClassname);
            $element.classList.add(this.settings.focusedClassname);
        });

        $element.querySelector('select, input, textarea').addEventListener('focusout', () => {
            $element.classList.remove(this.settings.focusedClassname);
        });
    }

    checkField ($element) {
        const formType = $element.getAttribute(this.settings.initAttr);

        switch (formType) {
        case 'select': {
            const $field = $element.querySelector('select');
            let filled = true;

            if ($field.value === '') {
                filled = false;
            }

            if ($field.value === 0 || $field.value === '0') {
                const $emptyOption = $field.querySelector('option[value="0"]');
                if ($emptyOption.innerText === '') {
                    filled = false;
                }
            }

            if (filled === false) {
                $element.classList.remove(this.settings.filledClassname);
            } else {
                $element.classList.add(this.settings.filledClassname);
            }

            break;
        }
        case 'radio': {
            break;
        }
        case 'checkbox': {
            break;
        }

        default: {
            const $field = $element.querySelector('input, textarea');

            if ($field.value !== '') {
                $element.classList.add(this.settings.filledClassname);
            } else {
                $element.classList.remove(this.settings.filledClassname);
            }
        }
        }
    }
}

export { FormField };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;
    if ($context) {
        const $$formField = $context.querySelectorAll('[data-formfield]');
        for (let i = 0; i < $$formField.length; i++) {
            const $formField = $$formField[i];

            $formField.API = new FormField($formField);
        }
    }
});
